import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, {useState, useContext} from "react"
import { Feature, SiteMetadata } from "../components"
import { useModal } from "../context"
import { Layout } from "../layouts/Layout"
import { Container, Row, Col, Toast, InputGroup, Button } from "react-bootstrap"

import CartContext from '../context/CartContext'
import ym from 'react-yandex-metrika'

export default props => {
  const { data, location } = props
  const product = data.item
  const navigation = location.state ? location.state.navigation : null
  const { modal } = useModal()

  const [show, setShow] = useState(false);

  const goodToast = <Toast.Body className="alert alert-success m-0 text-center">Успешно добавлено в корзину!</Toast.Body>
  const badToast = <Toast.Body className="alert alert-danger m-0 text-center">Выберите опции товара!</Toast.Body>
  const [toastBody, setToastBody] = useState()
  
  const [toogleStatus, setToogleStatus] = useState([product.feature == null, product.variants == null])
  const [btnStatus, setBtnStatus] = useState(!(toogleStatus[0] && toogleStatus[1]))
  const [count, setCount] = useState(1)

  const changeCountPlus = (e) => {
    setCount(count + 1)
    e.preventDefault()
  }
  const changeCountMinus = (e) => {
    if(count-1 > 0) setCount(count - 1)
    e.preventDefault()
  }


  let price = <span itemProp="price">{(product.price).toFixed(2)}</span>
  if((product.feature) !== null) {
  price = <><span itemProp="price">{(product.feature.varesi[0].price).toFixed(2)}</span>-<span>{(product.feature.varesi[product.feature.varesi.length - 1].price).toFixed(2)}</span></>
  }
  const [currentPrice, setPrice] = useState(price)
  const changePrice = (index) => {
    setPrice(<span itemProp="price">{(product.feature.varesi[index].price).toFixed(2)}</span>) 
    setVares([currentVares[0] = index, currentVares[1]])   
    changeButtonStatus(0)
  };

  const [currentVares, setVares] = useState([null, null])
  const changeVares = (index) => {
    setVares([currentVares[0], currentVares[1]  = index])   
    changeButtonStatus(1)
  };

  const changeButtonStatus = (index) => {
    toogleStatus[index] = true      
    if(toogleStatus[0] && toogleStatus[1]) setBtnStatus(false)
    else setToogleStatus(toogleStatus)
  }

  const {addToCart} = useContext(CartContext)

  const clickAddToCart = (e) => {
    if (!btnStatus){
      addToCart(product.id, count, currentVares[0], currentVares[1])
      setToastBody(goodToast)
      ym('reachGoal','add-to-cart')
    } else setToastBody(badToast)
    setShow(true)
    e.preventDefault()
  }

  return (
    <Layout navigation={navigation}>
      <SiteMetadata 
        title={product.name} 
        description = {product.shortdesc !== '' ? product.shortdesc : product.description}
        image={product.images.childImageSharp.fluid.src} 
        canonical={`/shop/${product.slug_category}${product.slug}/`}
        />
      {modal ? <></> : 
        <>
          <Container className="mb-4">
            <Row>
              <Col>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center" itemScope itemType="http://schema.org/BreadcrumbList">
                  <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/" itemProp="item">
                      <span itemProp="name">Главная</span>
                    </Link>
                    <meta itemProp="position" content="1"/>
                  </li>
                  <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/shop/" itemProp="item">
                      <span itemProp="name">Продукция</span>
                    </Link>
                    <meta itemProp="position" content="2"/>
                  </li>
                  {product.slug_category === '' ? <></> : 
                    <li className="breadcrumb-item"  itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                      <Link to={`/shop/${product.slug_category}`} itemProp="item">
                        <span itemProp="name">{product.tag}</span>
                      </Link>
                      <meta itemProp="position" content="3"/>
                    </li>}
                  <li className="breadcrumb-item active" aria-current="page">{product.name}</li>
                </ol>
              </nav>              
              </Col>
            </Row>
          </Container>
        </>}

      <article className={modal && "max-h-80vh overflow-auto"} itemScope itemType="http://schema.org/Product">
        <div className="container my-1 text-center text-md-left">
          <Row>
            <Col md={5}>
              <Img  itemProp="image" fluid={product.images.childImageSharp.fluid} alt={product.name}/>
              <span className="sr-only" itemProp="brand">Пчелиный Хуторок</span>
            </Col>
            <Col className="mx-2">
              <Row className={modal ? "d-flex flex-column mt-3" : "d-flex flex-column mt-5"}>
                <h1 itemProp="name">{product.name}</h1>
                <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                  {currentPrice} <small> руб.</small>
                  <meta itemProp="priceCurrency" content="RUB" />
                  <link itemProp="availability" href="http://schema.org/InStock"/>
                </div> 
              </Row>
              <Row>
                <div className="w-100 mt-4 mb-3" itemProp="description" dangerouslySetInnerHTML={{ __html: product.description }} />  
              </Row>
              <Row className="d-flex flex-column mb-2">
                <Feature value={product.feature} func={changePrice}/> 
                <Feature value={product.variants} func={changeVares}/>
              </Row>
              <Row>
                <Col xs={12} sm className="px-0">
                  <InputGroup className="d-flex justify-content-center justify-content-sm-start mt-2 mb-3">
                    <InputGroup.Prepend>
                      <Button variant="primary" className="rounded"  style={{padding: ".5rem 1rem"}}  onMouseDown={changeCountMinus}>-</Button>
                      <InputGroup.Text style={{fontWeight: "900", padding: ".5rem 1rem", fontSize: "1.1rem"}}>{count}</InputGroup.Text>
                      <Button variant="primary" className="rounded"  style={{padding: ".5rem 1rem"}} onMouseDown={changeCountPlus}>+</Button>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
                <Col sm={8} className="d-sm-flex justify-content-sm-end px-sm-0">
                  <button className="btn btn-primary btn-lg rounded mt-2 mb-3" onMouseDown={clickAddToCart}>
                    Добавить в корзину
                  </button> 
                </Col>
              </Row>
               
                         
            </Col>
          </Row>          

          <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide 
            style={{
              width: "350px",
              position: "fixed",
              top: "30%",
              left: "50%",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              zIndex: "1000"
            }}
          >
            {toastBody}
          </Toast>

        </div>
      </article>
      
    </Layout>
  )
}

export const query = graphql`
  query SingleProductQuery($slug: String!) {
    item: productsJson(slug: { eq: $slug }) {
      id
      name
      slug
      slug_category
      tag
      description
      shortdesc
      price
      variants {
        label
        varesi
      }
      feature {
        label
        abbr
        varesi {
          value
          price
        }
      }
      images {
        childImageSharp {
          fluid(maxHeight: 500, maxWidth: 500, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
