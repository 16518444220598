import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import React, {useState, useEffect} from "react"

export function useModal() {
  const context = React.useContext(ModalRoutingContext)
  if (context === undefined) {
    throw new Error(
      `useModal must be used within an ModalRoutingContext.Provider`
    )
  }

  const [vh, setVh] = useState(typeof window !== 'undefined' ? window.innerHeight * 0.01 : 800)

  useEffect(() => { 
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [vh])

  if(typeof window !== 'undefined')
      window.addEventListener('resize', () => ( setVh(window.innerHeight * 0.01)))

  return context
}
